<template>
    <div class="exchange">
      <van-image
        fit="cover"
        class="exchange-header-image"
        :src="require('../../../static/images/v8/main-01.png')"
      />
  
      <div v-if="!isLoginState" class="exchange-container">
        <van-image
          fit="cover"
          class="exchange-container-image"
          :src="require('../../../static/images/v8/main-01.png')"
        />
  
        <div class="exchange-container-input">
          <van-field
            v-model="mobile"
            class="mobile-field"
            maxlength="11"
            size="large"
            input-align="center"
            placeholder="输入中国移动手机号"
            @blur="onMobileBlur"
          />
        </div>
  
        <div class="exchange-container-btn">
          限时兑换
        </div>
      </div>
  
      <!-- 兑换区域 -->
      <div v-if="isLoginState" class="exchange-confirm">
        <div class="exchange-confirm-header">
          {{ mobile }} 移动积分： {{ points }}
        </div>
  
        <div class="exchange-confirm-panel">
          <div class="exchange-confirm-panel-title">本次兑换面额</div>
  
          <div class="exchange-confirm-panel-info">
            <div class="exchange-confirm-panel-amount">
              <div class="exchange-confirm-panel-amount-val">
                {{ totalAmount }}
              </div>
              <div class="exchange-confirm-panel-amount-symbol">元</div>
            </div>
            <div class="exchange-confirm-panel-name">停车立减券包</div>
            <div class="exchange-confirm-panel-points">
              消耗移动积分 {{ totalPoints }}(畅由积分 {{ cyTotalPoints }})
            </div>
          </div>
  
          <van-row class="">
            <van-col span="24" class="sms-mobile">
              <van-field
                v-model="exchangeSmsCode"
                class="sms-field"
                maxlength="6"
                size="large"
                input-align="center"
                placeholder="输入验证码完成兑换"
                clearable
              >
                <template #button>
                  <van-button
                    size="small"
                    style="width: 85px"
                    @click="handleSendExchangeSMS"
                    :disabled="isExchangeCountDown"
                    >{{ exchangeCountdownLab }}</van-button
                  >
                </template>
              </van-field>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="24" class="exchange-submit">
              <van-button
                id="AUTHLOGIN"
                class="exchange-submit-btn"
                round
                color="#006bd9"
                @click="handleConfirmExchange"
              >
                立即兑换
              </van-button>
            </van-col>
          </van-row>
        </div>
      </div>
  
      <!-- 移动授权 -->
      <van-popup
        v-model="showCmccLogin"
        position="center"
        closeable
        close-icon="close"
        :close-on-click-overlay="false"
        :style="{ minHeight: '240px', width: '80%' }"
        round
      >
        <div class="pop">
          <!-- header -->
          <div class="pop-header">
            <div class="pop-header-title">移动授权登录</div>
          </div>
  
          <!-- button -->
          <div style="padding-top: 10px">
            <div class="pop-info">
              <van-image
                fit="cover"
                class="pop-icon"
                :src="require('../../../static/images/mobile-phone.png')"
              />
              <span class="pop-mobile">{{ mobile }}</span>
            </div>
            <div class="pop-info">
              <van-image
                fit="cover"
                class="pop-icon"
                :src="require('../../../static/images/vcode.png')"
              />
              <van-field
                v-model="smsCode"
                size="large"
                placeholder="请输入验证码"
                class="pop-val"
                maxlength="6"
                clearable
              >
                <template #button>
                  <van-button
                    size="small"
                    @click="handleSendSMS"
                    :disabled="isCountDown"
                    >{{ countdownLab }}</van-button
                  >
                </template>
              </van-field>
            </div>
          </div>
  
          <div class="pop-bottom">
            <van-button
              id="PAY"
              class="pop-btn"
              round
              color="#006bd9"
              @click="handleConfirmBind"
            >
              登 录
            </van-button>
          </div>
        </div>
      </van-popup>
    </div>
  </template>
    
  <script>
  import { Row, Col, Field, Button, Image, Popup } from "vant";
  import {
    authLogin,
    queryCmccBalanceBind,
    queryCmccBalanceNew,
    getBindMsgCode,
    bindInfoCmcc,
    getProduct,
    placeOrder,
    dectOrder,
    sendCmccSms,
  } from "@/api/global";
  
  export default {
    components: {
      [Row.name]: Row,
      [Col.name]: Col,
      [Field.name]: Field,
      [Button.name]: Button,
      [Image.name]: Image,
      [Popup.name]: Popup,
    },
    data() {
      return {
        mobile: "",
        smsCode: "",
        showSmsInput: false,
        isCountDown: false,
        countdownLab: "发送验证码",
        timer: null,
        tokenId: null,
        validateToken: null,
  
        showCmccLogin: false,
        isLoginState: false,
  
        // 积分相关
        points: 0,
        currentPoints: 0,
        productList: [],
        totalAmount: 0,
        totalPoints: 0,
        cyTotalPoints: 0,
        subbarTotalPoints: 0,
  
        reqOrderId: null,
  
        exchangeSmsCode: "",
        isExchangeCountDown: false,
        exchangeCountdownLab: "发送验证码",
      };
    },
    created() {
      window.merId = this.$route.query.merId;
      window.storeId = this.$route.query.storeId;
      window.channelCode = this.$route.query.channelCode;
  
      this.loadProduct();
    },
    methods: {
      onMobileBlur() {
        let lastMobile = localStorage.getItem("jtc_mobile") || null;
        if (lastMobile != this.mobile) {
        }
      },
  
      // 限时兑换
      handleAuthLogin() {
        if (this.mobile.length != 11) {
          this.$toast("请输入合法的手机号");
          return;
        }
  
        const _this = this;
        authLogin({
          mobile: this.mobile,
          channelCode: this.$route.query.channelCode,
        }).then((res) => {
          console.log(res);
          localStorage.setItem("jtc_login_token", res.loginToken);
          localStorage.setItem("jtc_mobile", res.mobile);
  
          let data = {
            fingerprint: window.blackbox,
            sessionid: window.sessionId,
            channelCode: _this.$route.query.channelCode,
          };
  
          if (this.$route.query.id) {
            data.transCode = this.$route.query.id;
          }
  
          if (this.$route.query.ddback) {
            data.ddback = this.$route.query.ddback;
          }
  
          queryCmccBalanceBind(data)
            .then((qres) => {
              // {points: '2563'}
              this.isLoginState = true;
              this.points = qres.points || 0;
              this.currentPoints = (qres.points || 0) > 6480 ? 6480 : qres.points;
  
              this.calcExchangeVal();
            })
            .catch((err) => {
              // 1008611 这个返回码是 获取绑定地址失败的
              // {"success":false,"message":"用户未绑定","code":1001,"result":{"msg":"1分钟内只允许发送一次验证码！请稍后重试","tokenId":"1分钟内只允许发送一次验证码！请稍后重试","retVal":"803","validateToken":"T4cYpLmmKMQeg+vK+DNJX79ydmPZeUspx+Ecq/6oGOKZvmjmubc2KwcL2oiEmLmnUarRIzixvC4hskbGqDBsBw=="},"timestamp":1670933717770}
              // {"success":false,"message":"用户未绑定","code":1001,"result":{"msg":"系统繁忙，请稍候重试","tokenId":"系统繁忙，请稍候重试","retVal":"801","validateToken":"LOq3jn0rXbqQg/SQJ1eIjRAa3x1+1I/NE8hXKM3UMgByLsE6WszMU6VWXSz70Yja1xw/NPvlnk8ovZL6C45TlQ=="},"timestamp":1670933709962}
              // {"success":false,"message":"用户未绑定","code":1001,"result":{"msg":"短信发送成功","tokenId":"短信发送成功","retVal":"888","validateToken":"tArfz0ulLLeePw1xXtwJCsIdbYoDhtyOzSaNdP5I5fSPhqDULB/4Mz6Kx68+LnTFXcxDRVL0DRA/Fz2srARTyw=="},"timestamp":1670933672486}
              _this.validateToken = err.result.validateToken || null;
              _this.tokenId = err.result.tokenId || null;
  
              if (err.result.retVal == "888") {
                _this.showCmccLogin = true;
                _this.isCountDown = true;
                _this.startCountDown();
              }
  
              _this.$toast(err.result.msg);
            });
        });
      },
  
      // 登录后查询积分
      async loginCallbackGetPoints() {
        let data = {};
        if (this.$route.query.channelCode) {
          data.channelCode = this.$route.query.channelCode;
        }
  
        if (this.$route.query.id) {
          data.transCode = this.$route.query.id;
        }
  
        if (this.$route.query.merId) {
          data.merId = this.$route.query.merId;
        }
  
        if (this.$route.query.storeId) {
          data.storeId = this.$route.query.storeId;
        }
  
        if (this.$route.query.ddback) {
          data.ddback = this.$route.query.ddback;
        }
  
        data.fingerprint = "1";
        data.sessionid = "1";
  
        let balanceResp = await queryCmccBalanceNew(data);
  
        try {
          console.log("1122 queryCmccBalanceNew >>> ", balanceResp);
          if (balanceResp) {
            console.log("queryCmccBalanceNew >>> ", balanceResp);
            if (balanceResp) {
              if (balanceResp.resultCode == "0000") {
                this.points = balanceResp.points || 0;
                this.currentPoints =
                  (balanceResp.points || 0) > 6480 ? 6480 : balanceResp.points;
                this.isLoginState = true;
                this.calcExchangeVal();
              } else if (balanceResp.resultCode == "1114") {
                // window.location.reload()
  
                balanceResp = await queryCmccBalanceNew(data);
                if (balanceResp.resultCode == "0000") {
                  this.points = balanceResp.points || 0;
                  this.currentPoints =
                    (balanceResp.points || 0) > 6480 ? 6480 : balanceResp.points;
                  this.isLoginState = true;
                  this.calcExchangeVal();
                }
              }
            }
          }
        } catch (error) {
          console.log("queryCmccBalanceNew err >>>>", error);
        }
      },
  
      // 获取商品
      async loadProduct() {
        let productResp = await getProduct({
          pageNo: 1,
          pageSize: 1000,
        });
  
        if (productResp) {
          console.log("res >>>> ", productResp);
          if (productResp.records && productResp.records.length > 0) {
            this.productList = productResp.records || [];
            this.productList.map((item) => {
              item.exchangeCount = 0;
              item.exchangePoints = 0;
              item.enablePlus = true;
              return;
            });
          }
        }
      },
  
      // 计算兑换最高
      calcExchangeVal() {
        if (this.points < 240) {
          return;
        }
  
        let list = Object.assign([], this.productList);
        list.sort((a, b) => b.goodsPrice - a.goodsPrice);
  
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          if (this.currentPoints >= item.goodsPrice) {
            item.exchangeCount = parseInt(this.currentPoints / item.goodsPrice);
            item.exchangePoints = item.exchangeCount * item.goodsPrice;
            this.currentPoints = this.currentPoints - item.exchangePoints;
          }
        }
  
        this.checkEabledPlus();
        this.handlePlaceOrder();
      },
  
      checkEabledPlus(item, index) {
        let sumPoints = 0;
        let sumCount = 0;
        let sumAmount = 0;
  
        this.exchangeTip = "";
        this.productList.map((el) => {
          el.enablePlus = parseInt(this.currentPoints / el.goodsPrice) <= 0;
          sumPoints += el.exchangePoints;
          sumCount += el.exchangeCount;
          sumAmount += el.exchangeCount * parseInt(el.goodsDesc);
  
          this.configExchangeTip(el);
          return el;
        });
  
        this.productList = Object.assign([], this.productList);
  
        this.totalAmount = sumAmount;
        this.totalPoints = sumPoints;
        this.subbarTotalPoints = sumPoints * 100;
        this.cyTotalPoints = parseInt(sumPoints / 120) * 100;
      },
  
      configExchangeTip(item) {
        if (item.exchangeCount > 0) {
          this.exchangeTip += item.goodsName + ` x ${item.exchangeCount}; `;
        }
      },
  
      // 发送兑换验证码
      handleSendExchangeSMS() {
        if (!this.reqOrderId) {
          this.$toast("无法查询订单信息，请稍后再试");
          return;
        }
  
        sendCmccSms({
          reqOrderId: this.reqOrderId,
        }).then(() => {
          this.isExchangeCountDown = true;
          this.startExchangeCountDown();
        });
      },
  
      startExchangeCountDown() {
        const _t = this;
        let initVal = 60;
        this.timer = setInterval(() => {
          if (initVal <= 0) {
            _t.isExchangeCountDown = false;
            _t.exchangeCountdownLab = "获取验证码";
  
            clearInterval(_t.timer);
            return;
          }
  
          initVal -= 1;
          _t.exchangeCountdownLab = initVal + "s";
        }, 1000);
      },
  
      // 下单
      handlePlaceOrder() {
        console.log("sid >>> ", window.sessionId, window.blackbox);
        if (!window.sessionId) {
          this.$toast("服务初始异常");
          return;
        }
  
        if (!window.blackbox) {
          this.$toast("服务初始异常");
          return;
        }
  
        let filters = this.productList.filter((item) => {
          return item.exchangeCount == 0;
        });
        if (filters.length == this.productList.length) {
          this.$toast("请选择兑换礼包数量");
          return;
        }
  
        let goodsInfo = [];
        this.productList.forEach((item) => {
          if (item.exchangeCount > 0) {
            goodsInfo.push({
              goodsNo: item.goodsNo,
              goodsNum: item.exchangeCount,
            });
          }
        });
  
        let data = {
          fingerprint: window.blackbox,
          goodsInfo,
          totalPoints: this.totalPoints,
          sessionid: window.sessionId,
        };
  
        if (this.$route.query.channelCode) {
          data.channelCode = this.$route.query.channelCode;
        }
  
        if (this.$route.query.merId) {
          data.merId = this.$route.query.merId;
        }
  
        if (this.$route.query.storeId) {
          data.storeId = this.$route.query.storeId;
        }
  
        if (this.$route.query.id) {
          data.transCode = this.$route.query.id;
        }
  
        placeOrder(data).then((res) => {
          if (res) {
            this.reqOrderId = res.requestId;
            this.isExchangeCountDown = true;
            this.startExchangeCountDown();
          }
        });
      },
  
      // 确认兑换
      handleConfirmExchange() {
        if (this.points < 240) {
          this.$toast("积分不足");
          return;
        }
  
        if (!this.reqOrderId) {
          this.$toast("无法查询订单信息，请稍后再试");
          return;
        }
  
        if (!this.exchangeSmsCode || this.exchangeSmsCode.length == 0) {
          this.$toast("请输入验证码");
          return;
        }
  
        dectOrder({
          fingerprint: window.blackbox,
          reqOrderId: this.reqOrderId,
          smsCode: this.exchangeSmsCode,
          sessionid: window.sessionId,
        }).then(() => {
          let query = this.$route.query;
          query.orderId = this.reqOrderId;
  
          if (this.$route.query.channelCode) {
            query.channelCode = this.$route.query.channelCode;
          }
  
          if (this.$route.query.merId) {
            query.merId = this.$route.query.merId;
          }
  
          if (this.$route.query.storeId) {
            query.storeId = this.$route.query.storeId;
          }
  
          if (this.$route.query.id) {
            query.id = this.$route.query.id;
          }
  
          if (this.$route.query.ddback) {
            query.ddback = this.$route.query.ddback;
          }
  
          query.cyPoints = this.cyTotalPoints;
          query.productTip = `${this.exchangeTip}`;
  
          this.$router.push({
            path: "/res",
            query,
          });
        });
      },
  
      startCountDown() {
        const _t = this;
        let initVal = 60;
        this.timer = setInterval(() => {
          if (initVal <= 0) {
            _t.isCountDown = false;
            _t.countdownLab = "获取验证码";
  
            clearInterval(_t.timer);
            return;
          }
  
          initVal -= 1;
          _t.countdownLab = initVal + "s";
        }, 1000);
      },
  
      handleSendSMS() {
        if (this.mobile.length != 11) {
          this.$toast("请输入合法的手机号");
          return;
        }
  
        getBindMsgCode({
          tokenId: this.tokenId,
          validateToken: this.validateToken,
          channelCode: this.$route.query.channelCode,
        })
          .then((res) => {
            this.tokenId = res.tokenId || null;
            this.validateToken = res.validateToken || null;
  
            if (res.retVal == "888") {
              this.showSmsInput = true;
              this.isCountDown = true;
              this.startCountDown();
            } else {
              this.$toast(res.msg);
            }
          })
          .catch((err) => {
            this.tokenId = err.result.tokenId || null;
            this.validateToken = err.result.validateToken || null;
  
            _this.$toast(err.result.msg || "短信发送失败");
          });
      },
      // 提交绑定
      handleConfirmBind() {
        if (this.mobile.length != 11) {
          this.$toast("请输入合法的手机号");
          return;
        }
  
        if (this.smsCode.length != 6) {
          this.$toast("请输入验证码");
          return;
        }
  
        const _this = this;
        let data = {
          smsCode: _this.smsCode,
          tokenId: _this.tokenId,
          validateToken: _this.validateToken,
          channelCode: _this.$route.query.channelCode,
        };
  
        if (this.$route.query.id) {
          data.transCode = this.$route.query.id;
        }
  
        if (this.$route.query.ddback) {
          data.ddback = this.$route.query.ddback;
        }
  
        bindInfoCmcc(data)
          .then((res) => {
            console.log("res >>>>>>> ", res);
            if (res.retVal == "888") {
              let query = _this.$route.query;
              if (this.$route.query.channelCode) {
                query.channelCode = _this.$route.query.channelCode;
              }
  
              if (_this.$route.query.merId) {
                query.merId = _this.$route.query.merId;
              }
  
              if (_this.$route.query.storeId) {
                query.storeId = _this.$route.query.storeId;
              }
  
              if (_this.$route.query.id) {
                query.id = _this.$route.query.id;
              }
  
              if (_this.$route.query.ddback) {
                query.ddback = _this.$route.query.ddback;
              }
              _this.showCmccLogin = false;
              _this.loginCallbackGetPoints();
            } else {
              _this.validateToken = res.validateToken || null;
              _this.tokenId = res.tokenId || null;
              _this.$toast(res.msg);
            }
          })
          .catch((err) => {
            _this.$toast(err.message);
          });
      },
    },
  };
  </script>
    
  <style lang="less" scoped>
  .exchange {
    position: relative;
    min-height: 100vh;
    padding-bottom: 10px;
    background-color: #f6f6f6;
  
    &-header-image {
      width: 100%;
      min-height: 400px;
    }
  
    &-container {
      position: absolute;
      top: 25%;
      margin: 0 15px;
  
      &-input {
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 15px;
      }
  
      &-btn {
        background-color: #40c3c3;
        color: #fff;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 25px;
        margin: 15px 15px;
      }
  
      &-button {
        
      }
    }
  
    &-content {
      position: relative;
      background: linear-gradient(to bottom, #1d81e5, #9ccefd, #9ccefd, #fff);
      margin: 0px 20px;
      padding: 30px 20px 30px;
      border-radius: 10px;
      z-index: 99;
  
      &-img {
        padding-bottom: 20px;
      }
    }
  
    &-confirm {
      position: relative;
      top: -200px;
  
      &-header {
        border: 1px solid #fff;
        background-color: #9ccefd;
        color: #fff;
        text-align: center;
        padding: 10px 10px;
        margin: 0px 30px;
        border-radius: 5px;
        font-size: 20px;
      }
  
      &-panel {
        background-color: #fff;
        margin: 20px 20px;
        padding: 20px 10px 30px;
        border-radius: 10px;
        z-index: 99;
  
        &-title {
          text-align: center;
          font-size: 22px;
          font-weight: 600;
          color: #006bd9;
        }
  
        &-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 20px;
        }
  
        &-amount {
          display: flex;
          align-items: baseline;
          color: #006bd9;
  
          &-val {
            font-size: 60px;
          }
  
          &-symbol {
            margin-top: -10px;
            font-size: 20px;
          }
        }
  
        &-name {
          padding-top: 10px;
          font-size: 21px;
          color: #006bd9;
        }
  
        &-points {
          font-size: 14px;
          padding-top: 10px;
          padding-bottom: 15px;
          color: #999;
        }
      }
    }
  
    &-guize {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: center;
  
      &-tip {
        width: 85%;
        margin-bottom: 10px;
      }
    }
  
    &-rules {
      position: relative;
      top: -200px;
      background-color: rgba(255, 255, 255, 1);
      margin: 20px;
      padding: 10px 5px;
      border-radius: 15px;
      z-index: 99;
  
      &-panel {
        background-color: #fff;
        border-radius: 10px;
        padding: 10px 10px 10px;
  
        &-title {
          width: 100%;
          font-weight: 500;
          text-align: center;
        }
      }
  
      &-info {
        padding-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        line-height: 160%;
  
        &-tip {
          font-size: 13px;
          color: #666;
        }
  
        &-img {
          width: 96%;
          padding-left: 2%;
          padding-top: 10px;
          padding-bottom: 0px;
        }
      }
    }
  
    &-tip {
      width: 150px;
      margin-left: 50%;
      padding-bottom: 10px;
      transform: translateX(-50%);
    }
  
    &-submit {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0px;
    }
  
    &-submit-btn {
      margin-top: 20px;
      width: 90%;
      // padding-top: 10px;
      // -webkit-animation-name: scaleDraw; /*关键帧名称*/
      // -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
      // -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
      // -webkit-animation-duration: 2s; /*动画所花费的时间*/
    }
  }
  
  .input-mobile {
    width: 96%;
    height: 55px;
    margin: 0 2%;
    // border-radius: 30px;
    // box-shadow: 0px 2px 8px #ddd;
    overflow: hidden;
  }
  
  .mobile-field {
    height: 50px;
    line-height: 25px;
    font-size: 16px;
    border-radius: 30px;
    font-weight: 600;
  }
  
  .sms-mobile {
    width: 90%;
    height: 55px;
    margin: 10px 5% 0;
    border-radius: 30px;
    // box-shadow: 0px 2px 8px #ddd;
    overflow: hidden;
  }
  
  .sms-field {
    height: 55px;
    line-height: 15px;
    font-size: 16px;
    border-radius: 30px;
    font-weight: 600;
    border: 1px solid #006bd9;
  }
  
  @keyframes scaleDraw {
    /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0% {
      transform: scale(1);
      /*开始为原始大小*/
    }
  
    25% {
      transform: scale(1.1);
      /*放大1.1倍*/
    }
  
    50% {
      transform: scale(1);
    }
  
    75% {
      transform: scale(1.1);
    }
  }
  
  .pop {
    position: relative;
    height: 100%;
    background-color: #fff;
  
    &-header {
      background-color: #fff;
      color: #006bd9;
      height: 48px;
      width: 100%;
  
      &-title {
        width: 100%;
        height: 58px;
        line-height: 58px;
        color: #006bd9;
        text-align: center;
        font-size: 19px;
        font-weight: 600;
      }
  
      &-tiplab {
        padding: 0px 20px 0;
        font-size: 14px;
        color: #666;
      }
  
      &-tipval {
        padding: 0px 20px 0;
        font-size: 14px;
        color: #f56723;
      }
    }
  
    &-process {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &-step {
      width: 80%;
    }
  
    &-info {
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #ddd;
      padding: 10px 0;
    }
  
    &-mobile {
      padding: 15px 0px;
      padding-left: 10px;
      font-size: 17px;
      font-weight: 600;
    }
  
    &-icon {
      width: 25px;
      height: 25px;
      padding-left: 20px;
    }
  
    &-val {
      font-size: 17px;
      font-weight: 600;
    }
  
    &-title {
      &-lab {
        margin-top: 30px;
        color: #666;
        padding: 0 20px;
        font-size: 13px;
      }
    }
  
    &-bottom {
      padding: 30px 0;
      display: flex;
      justify-content: center;
    }
  
    &-btn {
      width: 80%;
    }
  }
  </style>
    